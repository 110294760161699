.overlay {
    opacity: 0;
    transition: opacity ease 300ms
}

.projectCard:hover > .overlay {
    opacity: 1;
}

a {
    color: #000000;
    text-decoration: none;
}

.border-radius {
    border-radius: '0.8rem';
}

.drop-shadow {
    box-shadow: '0 0 10px rgba(0, 0, 0, 0.25)';
}